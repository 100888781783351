var $ = require('jquery')
var tri = require('tripartite')

require('./pages')
var UploadableImage = require('ei-pic-browser/uploadable-image')
let CKEditorDrop = require('ei-pic-browser/ckeditor-drop')

let fileFunctionsPrefix = '/webhandle-page-editor'

window.CKEDITOR_BASEPATH = '/ckeditor/' 
 
async function appSetup() { 
    let escapeAttributeValue = require('../server-js/utils/escape-attribute-value') 
    tri.templates['escAttr'] = (val) => { 
        if (val && typeof val == 'string') { 
            return escapeAttributeValue(val, true) 
        } 
        return val 
    } 
 
    // custom config 
    CKEDITOR.config.customConfig = '/conf/sports-config.js'
	$('.app-page input[type=text].picture-input-field').each(function() {
			new UploadableImage(this)
	})


	require('webhandle-page-editor/app-client')
	require('../node_modules/@dankolz/sponsors/client-js/sponsors.js')
	require('../node_modules/@dankolz/webhandle-news/client-js/app.js')
	
	CKEDITOR.on('instanceReady', (evt) => {

		// evt.editor.on( 'fileUploadRequest', function( evt ) {
		// })
		// evt.editor.on( 'drop', function( evt ) {
		// })
		let id = evt.editor.id
		let ckDrop = new CKEditorDrop('#' + id)
		ckDrop.fileUploadUrlPrefix = fileFunctionsPrefix + ckDrop.fileUploadUrlPrefix
		ckDrop.imageLayouts = []
		ckDrop.render()
	})
}
if(window.CKEDITOR) {
    appSetup()
}
else {
    let ckscript = document.createElement('script');
    ckscript.setAttribute('src','/ckeditor/ckeditor.js');
    ckscript.onload = function() {
        appSetup()
    }
    document.head.appendChild(ckscript)
}



/*
require('../node_modules/@dankolz/ei-people-groups-1/client-js/people-groups-client.js')
require('../node_modules/webhandle-calendar/client-js/calendar-app-client.js')
require('../node_modules/@dankolz/webhandle-jobs/client-js/jobs-client.js')
require('ei-slideshow-1/client-js/slideshow-client')
*/

