var $ = require('jquery')
var PicUpload = require('./pic-upload')
var Dialog = require('ei-dialog')
var _ = require('underscore')

var ckeditorDrop = function(id, targetDirectory) {
	this.id = id
	if(targetDirectory) {
		this.targetDirectory = targetDirectory
	}
	else {
		var prefixParts = window.location.pathname.split('/')
		prefixParts.pop()
		this.targetDirectory = '/img' + prefixParts.join('/')
	}
	this.fileUploadUrlPrefix = '/files/upload'
}

function getInstanceById(id) {
	if(id in CKEDITOR.instances) {
		return CKEDITOR.instances[id]
	}
	for(let instance of Object.values(CKEDITOR.instances)) {
		if(instance.id == id) {
			return instance
		}
	}
	return null
}

ckeditorDrop.prototype.getEditorInstance = function() {
	return getInstanceById(this.id.substring(1))
}

ckeditorDrop.prototype.insertDocumentLink = function(url, desc) {
	var edInstance = this.getEditorInstance()
	edInstance.insertHtml('<a href="' + url + '" ><img src="' + this.picUpload.documentInsertionImage + '" /></a>')
}

ckeditorDrop.prototype.insertImage = function(url, desc) {
	var edInstance = this.getEditorInstance()
	var classes = this.picUpload.getLayoutClasses()
	classes = classes ? classes : ''
	// edInstance.insertHtml('<img alt="' + desc + '" class="' + classes + '" src="' + url + '" />')

	edInstance.insertHtml(`
	<figure class="flex-picture ${classes}" 
		data-additionalclasses="${classes}" data-additionalstyles="" data-align="" data-alttext="${desc}" data-aspectratio="" data-bordercss="" 
		data-layout="" data-link="" data-linktarget="false" data-marginbottom="" data-marginleft="" data-marginright="" data-margintop="" 
		data-paddingbottom="" data-paddingleft="" data-paddingright="" data-paddingtop="" 
		data-picsource="${url}" data-scaling="" data-targetheight="" data-targetwidth="" data-usecaption="false" data-verticalalign="" style="">
		<div class="pic">
			<picture style="display: block; position: relative;"> <img alt="${desc}" src="${url}" style="display: block; max-height: 100%; max-width: 100%;"/> </picture>
		</div>

		<figcaption style="display: none;">
			<p>
				&nbsp;
			</p>
		</figcaption>
	</figure>
	`)
}

async function toBinaryBuffer(data) {
	if(typeof data === 'string') {
		if(data.startsWith('data:image')) {
			let a = await fetch(data)
			let b = await a.arrayBuffer()
			return b

		}
		else {
			return data
		}

	}
	return data
}

ckeditorDrop.prototype.render = function() {
	var self = this
	var uploadImage = function(data, name, doneCallback) {
		var finalUrl = self.targetDirectory + (self.targetDirectory.endsWith('/') ? '' : '/') + name
		
		async function doUpload() {
			try {
				// await webhandle.sinks.public.mkdir(self.targetDirectory)
				await webhandle.sinks.public.write(finalUrl, await toBinaryBuffer(data))
				if(doneCallback) {
					doneCallback()
				}
			}
			catch(e) {
				console.log(e)
				if(doneCallback) {
					doneCallback(e)
				}
			}
		}
		doUpload()
		// var msg = $.ajax({
		// 	url: self.fileUploadUrlPrefix + self.targetDirectory,
		// 	type: 'POST',
		// 	cache: false,
		// 	data: {
		// 		dataUrl: data,
		// 		dataFilename: name
		// 	}
		// })
		
		
		return finalUrl
	}

	var picUpload = new PicUpload(this.id, function() {
		
		var $diagBody = $('.dialog-frame .body')
		if($diagBody.length == 0) {
			var diag = new Dialog({
				title: 'Upload',
				body: function(container) { 
					$(container).append('<p></p>')
					return ''
				},
				on: {
					'.btn-ok': function() {
						// var edInstance = CKEDITOR.instances[self.id.substring(1)]
						if(picUpload.isImage()) {
							var imageUrl = uploadImage(picUpload.getImageData(), picUpload.getImageName(), () => {
								self.insertImage(imageUrl, picUpload.getImageDescription())
								picUpload.cleanup()
								diag.close()
							})
						}
						else {
							var imageUrl = uploadImage(picUpload.getImageData(), picUpload.getImageName(), () => {
								self.insertDocumentLink(imageUrl, picUpload.getImageDescription())
								picUpload.cleanup()
								diag.close()
							})
						}
						return false
					},
					'.btn-cancel': function() {
						picUpload.cleanup()
					}
				}
			})
			diag.open()
		}
		
		
		return '.dialog-frame .body'
	})
	picUpload.imageLayouts = this.imageLayouts || []
	picUpload.render()
	this.picUpload = picUpload
	return this
}

module.exports = ckeditorDrop